.banner{
	padding: 180px 0px 230px 0px;
	background:rgba(0, 0, 0, 0.03);
}

.banner-content{
	span{
		color: $black;
		letter-spacing: 1px;
		text-transform:capitalize;
	}
	h1{
		line-height: 1.2;
		font-size: 65px;
		font-weight: 700;
		margin: 0px 0px 10px 0px;
		text-transform: capitalize;
		letter-spacing: -2px;
  		color: $primary-color;
	}
	p{
		margin-bottom: 35px;
		color: $black;
	}
}

.banner-img{
	position: relative;
	margin-right: -48px;
}

.video-play{
	color: $black;
	margin-left:15px;
	font-weight: 600;
	-webkit-transition: all .45s ease;
	   -moz-transition: all .45s ease;
	    -ms-transition: all .45s ease;
	     -o-transition: all .45s ease;
	        transition: all .45s ease;

	i{
		font-size: 14px;
		width: 50px;
		height:50px;
		background:rgba(23, 33, 130,.06);
		text-align: center;
		padding-top: 18px;
		border-radius: 100%;
		margin-right: 15px;
		-webkit-transition: all .45s ease;
	   -moz-transition: all .45s ease;
	    -ms-transition: all .45s ease;
	     -o-transition: all .45s ease;
	        transition: all .45s ease;
	}

	&:hover i{
		background: $primary-color;
		color: $light;
	}
}




@include mobile-xs{
	.banner-content h1 {
	    font-size: 51px;
	}

	.banner-img {
	    margin-right: 0px;
	}
}

@include mobile-xs{
	.banner-content h1 {
	    font-size: 51px;
	}

	.banner-img {
	    margin-right: 0px;
	}
}

@include tablet{
	.banner-content h1 {
	    font-size: 51px;
	}

	.banner-img {
	    margin-right: 0px;
	}
}

@include desktop{
	.banner-content  {
	    text-align: center;
	}

	.banner-img {
	    margin-right: 0px;
	}
}
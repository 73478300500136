.contact_form{
	background: #f7f7f7;
	padding: 40px;
}

.form-control{
	height:55px;
	border-color:#eee;
}

textarea.form-control{
	height:auto;
}

#map{
	height: 690px;
}

.contact-info-wrap{
	padding: 100px 0px 0px 0px;
}
.contact-info{
	padding: 30px;
	text-align: center;
	flex-basis: 33.33%;
	margin: 0px 10px;

	i{
		font-size: 40px;
		color:$primary-color;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		background: #eee;
		display: inline-block;
		text-align: center;
		padding-top: 30px;
	}

	h4{
		margin-bottom: 10px;
		margin-top: 30px;
		text-transform: capitalize;
	}
}


@include desktop{
	#map{
		height: 390px;
	}
}
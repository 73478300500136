
/** --------------------------------------------------
Author       : Dreambuzz
Template Name: Digicon - Digital Marketing Template
Author URI : themeturn.com
Description: digicon,agency,marketing,social media, Digital marketing,multipage,bootstrap, responsive,modern,agency,landing template.
Version      : 1.0
* -------------------------------------------------  */


/*-----------------------------
 CSS INDEXING
* --------------------------------------
* 01.GLOBAL CSS STYLE
* 02.TYPOGRAPHY CSS STYLE
* 03.BUTTONS STYLE
* 04.HOMEPAGE CSS STYLE
    -LOGO CSS
    -MENU CSS
    -BACKGROUND
* 05.FEATURES CSS STYLE
* 06.SERVICE CSS STYLE
* 07.ABOUT CSS STYLE
* 08.COUNTER CSS STYLE
* 09.PRICING STYLE
* 10.WORKING PROCESS
* 11.TESTIMONIAL STYLE
* 12.LATEST BLOG CSS STYLE
* 13.CONTACT CSS STYLE
* 14.FOOTER CSS STYLE
* ----------------------------------------
*/


/*--------------------
 TYPOGRAPHY
-----------------*/
@import url('https://fonts.googleapis.com/css?family=poppins:400,600,700&display=swap|Lato:400');
body {
  line-height: 1.7;
  font-family: $secondary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: $base-color;
  font-weight: 400;

}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: $primary-font; 
  color: $black;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing:-.5px;
}

h1,.h1 {
  font-size:40px;
  font-weight:800;
}
h2,.h2 {
  font-size: 32px;
}
h3,.h3 {
  font-size: 24px;
}
h4,.h4 {
  font-size:20px;
}

h5,.h5 {
  font-size:18px;
}
h6,.h6 {
  font-size:16px;
}


h1 a,h2 a,h3 a,h4 a,h5 a,h6 a{
  color: $black;

  &:hover{
    color: $primary-color;
  }
}


a{
  -webkit-transition: all .45s ease|linear;
     -moz-transition: all .45s ease|linear;
      -ms-transition: all .45s ease|linear;
       -o-transition: all .45s ease|linear;
          transition: all .45s ease|linear;
}
p {
  color:#7B7B7B;
}
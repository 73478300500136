$light: #fff;
$base-color: #858585;
$primary-color: #172182;
$secondary-color: rgb(255, 105, 132);
// $black: rgb(34, 34, 34);
$black: #111;
$border-color:#dedede;
$primary-font:  'Poppins', sans-serif;
$secondary-font:'Lato', sans-serif;
$extra-font:'Dosis', sans-serif;

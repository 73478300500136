// Pricing 

.pricing{
	position: relative;
	&:before{
		position: absolute;
		content: "";
		bottom:0px;
		left: 0px;
		background: #F7F7F7;
		background: url("../images/bg/t-pattern.png")no-repeat;
		background-size: cover;
		width: 100%;
		height:40%;
	}
}
.pricing-item{
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.03);
	background: $light;
	padding: 40px;

	.price-header{
		h2{
			font-size: 60px;
			color: $primary-color;
		}
		img{
			display: block;
			margin: 20px 0px;
		}
	}

	.price-features{
		margin: 15px 0px 25px;
		ul li{
			line-height: 35px;
			color: $black;

			i{
				margin-right: 10px;
			}
		}

	}

	.btn{
		&:hover{
			background: $primary-color;
			color: $light;
			border-color: $primary-color;
		}
	}
}
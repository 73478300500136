
/*-------------------
   MENU
----------------------*/

.main-navigation{
    z-index: 9999;
    background: #fff;
}


.navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    font-weight:600;
    text-transform:capitalize;
    font-size: 16px;
    color: #111;
}


.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0;
}



.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
    vertical-align: middle;
    background: no-repeat 50%;
    background-size: 100% 100%
}



.nav-item.dropdown {
    .dropdown-menu{
        -webkit-transition: all 300ms ease;
        -o-transition:all 300ms ease;
        transition: all 300ms ease;
        display: block;
        visibility: hidden;
        opacity: 0;
        top: 120%;
        -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
        box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
        border: 0px;
        padding: 0px;
        position: absolute;
    }
    &:hover .dropdown-menu{
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
}


.header-bar.fixed-header .main-navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(20,27,40,.90);
    border-color: transparent;

}


/*-------------------
  DROPDOWN MENU
----------------------*/


.dropdown-menu {
    opacity: 0
}

.dropdown-menu.show {
    animation: .2s forwards b;
}


.dropdown-item {
    font-size: .6875rem;
    font-weight: 500;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: background-color .1s;
    text-transform: uppercase;

    &:hover {
        color:$light;
        text-decoration: none;
        background-color: $primary-color;

    }
}




// Header SOcials


.header-contact{
    margin-top: 13px;

    a{
        font-size: 14px;
    }
    // span{
    //     color: $primary-color;
    //     font-weight: 700;
    //     margin-right: 20px;
    //     border:1px solid #ddd;
    //     display: inline-block;
    //     padding: 10px 20px;
    // }
}


.main_menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}
.b-logo,.w-logo{
    width: 50%;
}
.main_menu .b-logo{
    display: none;
}
.menu_fixed .w-logo{
    display: none;
}
.menu_fixed .b-logo{
    display: block;
}

.menu_fixed {
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    background-color: $light;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    top: 0;
}

.menu_fixed{
    .navbar-nav .nav-link{
        color: $black!important;
    }

    .header-socials a{
        color: $black;
    }
}

.dropdown-toggle::after{
    display: none;
}
// Blog Home

.post-meta{
	text-transform: capitalize;
	span{
		margin-right: 15px;
	}
}

.blog-item{
	background: $light;
	transition:.4s;
	cursor: pointer;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
	h3{
		font-size: 22px;
		font-family: $secondary-font;
	}

	.blog-item-content{
		padding: 20px;
	}


	&:hover h3 a{
		color: $primary-color;
	}
}


//  Blog Page

.blog-post-item{
	margin-bottom: 40px;
	.post-title{
		margin-top: 10px;
	}
	.post-meta{
		span{
			a{
				color: #888;

				&:hover{
					color: $primary-color;
				}
			}
		}
	}


	.post-tags{
		a{
			margin-right: 2px;
		}
	}
}


.sidebar-widget{
	.btn{
		color: $light;
	}
	.widget-title{
		margin-bottom: 20px;
	}

	a{
		color: $black;
	}

	&.cat-widget{
		ul li{
			margin-bottom: 10px;
		}
	}

	&.tags{
		a{
			display: inline-block;
			padding: 3px 9px;
			text-transform: capitalize;
			background: #eee;
			margin-bottom: 5px;
		}
	}
}


.post-navigation{
	margin-top: 70px;

	ul li.page-item .page-link{
		border-color: #eee;
	}
	ul li.page-item.active .page-link{
		background: #f7f7f7;
		color: $black;
	}
	ul li.page-item .page-link:hover{
		background: $primary-color;
		border-color: $primary-color;
		color: $light;
	}
}

//  POst Single
//  
.post-single {
	ul {
		margin: 20px 0px 30px 0px;
		li{
			color: $black;
			margin-bottom: 10px;
		}
	}
}

.post-single-thumb{
	margin-bottom: 25px;
}

.post-single-meta{
	margin: 30px 0px;
	padding: 15px;
	border:1px solid #f7f7f7;
	span{
		color: $black;
		margin-right: 15px;
	}

	a{
		color: #777;
	}

	i{
		color: $secondary-color;
	}
}

blockquote{
	background: $primary-color;
	margin: 25px 0px;
	padding: 30px;
	color: $light;
	font-size: 18px;
	line-height: 36px;
	border-left: 5px solid $secondary-color;
}

.blockquote-footer{
	margin-top: 20px;
	color: $secondary-color;
}

pre{
	background: #f7f7f7;
	padding: 30px 0px;
}

.post-sub-heading{
	margin-bottom: 25px;
}

.comments-list{
	padding-left: 0px;

	.media{
		border:1px solid #f7f7f7;
		padding: 20px;
		margin-bottom: 10px;
	}

	.comment-author{
		margin-bottom: 0px;
	}

	
	.comment-avatar{
		width: 80px;
		height: 80px;
		border-radius: 5px;
		margin-right: 20px;
	}

	.comment-info{
		margin-bottom: 10px;
	}

	.comment-button{
		margin-left: 10px;
		color: $primary-color;
	}
}

.post-comments-form{
	margin-top: 40px;
	padding: 35px;
	background:  #f7f7f7;

	
}



@include mobile-xs{
	.post-comments .media{
		display: block;
	}

	.comment-avatar{
		margin-bottom: 15px;
	}
}


@include mobile{
	.post-comments .media{
		display: block;
	}

	.comment-avatar{
		margin-bottom: 15px;
	}
}


@include desktop{
	.post-comments .media{
		display: block;
	}

	.comment-avatar{
		margin-bottom: 15px;
	}
}